
















































import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import { mapGetters, mapState } from "vuex";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import { accountingPointPlanForm } from "@/assets/scripts/form-fields/main";
import { MonthsEn } from "@/assets/scripts/form-fields/accounting-point-plan-form";
import AppApiMixin from "@/components/mixins/AppApi.vue";

const { values, attrs, validations, errors } = accountingPointPlanForm;

@Component({
  components: { XButton },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapState({ appEnviroment: "appEnviroment" }),
    ...mapGetters({
      pointId: "accountingPoint/pointId",
      pointName: "accountingPoint/name",
      pointServices: "accountingPoint/pointServices",
      pointPlanValues: "accountingPoint/pointPlanValues",
    }),
  },
  validations,
})
class AccountingPointPlanForm extends Mixins(XForm, AppApiMixin) {
  [x: string]: any;

  @Prop({ required: true }) readonly showSlider!: boolean;
  @Prop({ required: true }) readonly year!: number;
  @Prop({ required: true }) readonly serviceType!: string;
  @Prop({ required: true }) readonly serviceTypeId!: number;

  values = values;
  defaultValues = values;
  allFieldAttrs = attrs;
  defaultFieldAttrs = attrs;
  loading = false;

  fieldClassName = [
    "form__input",
    "form__input_text-align_right",
    "form__input_prefix_bold",
  ].join(" ");

  currentMonthIndex = new Date().getMonth() + 1;

  showSuccessChip = false;
  showErrorChip = false;
  error = "Ошибка";

  mounted() {
    this.setFormattedValues()
  }

  public get inputDisabled(): boolean {
    const { appEnviroment } = this;

    return !(appEnviroment.constants && appEnviroment.constants["ЛКЮЛДОГНАГР"]);
  }

  public get isCurrentYear() {
    return this.year === new Date().getFullYear();
  }

  public get sortedValues() {
    let sortedValues: any = {};
    for (let i in this.pointPlanValues) {
      sortedValues = {
        ...sortedValues,
        [this.pointPlanValues[i]["год"]]: {
          ...sortedValues[this.pointPlanValues[i]["год"]],
          [this.pointPlanValues[i]["услуга"]]: this.pointPlanValues[i],
        },
      };
    }

    return sortedValues;
  }

  public setFormattedValues() {
    if (
      this.sortedValues[this.year] &&
      this.sortedValues[this.year][this.serviceTypeId]
    ) {
      const chosenValues = this.sortedValues[this.year][this.serviceTypeId];
      if (chosenValues && Object.keys(chosenValues).length) {
        const monthKeys = [];

        for (let key in chosenValues) {
          if (key.match(/м\d\d/)) {
            monthKeys.push(key);
          }
        }

        //@ts-ignore
        const planValues = monthKeys.sort().map((key) => chosenValues[key]);
        planValues.forEach((value, i) => {
          const roundedValue = parseFloat(value.toFixed(6))
          this.values = { ...this.values, [MonthsEn[i + 1]]: roundedValue };
          this.allFieldAttrs = {
            ...this.allFieldAttrs,
            [MonthsEn[i + 1]]: {
              ...this.allFieldAttrs[MonthsEn[i + 1]],
              default: roundedValue,
            },
          };
        });
      }
    } else {
      const defaultValues: { [x: string]: number } = {};
      for (let month in MonthsEn) {
        defaultValues[month] = 0;
      }

      this.allFieldAttrs = this.defaultFieldAttrs;
      this.resetValuesToDefault();
    }
  }

  @Emit("")
  public emitCloseSliderEvt() {
    this.$emit("close-slider");
  }

  @Watch("showSlider")
  public showSliderChanged() {
    this.resetValuesToDefault();
  }

  @Watch("year")
  public yearChanged() {
    this.setValues();
  }

  @Watch("serviceType")
  public serviceTypeChanged() {
    this.setValues();
  }

  @Watch("pointPlanValues")
  public setValues() {
    this.setFormattedValues();
  }

  private resetValuesToDefault() {
    const fieldNames = Object.keys(this.values);
    const values: { [x: string]: string } = {};

    this.values = fieldNames.reduce((acc, fieldName) => {
      const defaultValue = this.allFieldAttrs[fieldName].default;

      return { ...acc, [fieldName]: defaultValue };
    }, values);

    this.showSuccessChip = false;
    this.showErrorChip = false;
  }

  public submitForm() {
    const data: { [x: string]: number } = {};

    for (let key in this.values) {
      //@ts-ignore
      const monthNum = MonthsEn[key];
      const monthKey = monthNum > 9 ? `м${monthNum}` : `м0${monthNum}`;
      data[monthKey] = +this.values[key];
    }

    this.loading = true;

    this.sendPlanValues(this.pointId, this.serviceTypeId, this.year, data)
      .then(async () => {
        await this.fetchPlanValues(this.pointId);
        this.showSuccessChip = true;
      })
      .catch((error: any) => {
        console.log(error);
        this.error = error;
        this.showErrorChip = true;
      })
      .finally(() => (this.loading = false));
  }
}

export default AccountingPointPlanForm;
